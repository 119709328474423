<template>
  <div class="ktz-homePrincipal ktz-section p-flex-column p-ai-center">
     <div class="ktz-img p-d-flex p-ai-center p-flex-column p-mt-2">
        <img style="width: 300px!important" src="../../../assets/logo.png" alt="teste" />
    </div>
     <span v-if="comecou" class="ktz-desconto p-mb-2" style="font-size: 22px;margin-top: -5px!important">Está acontecendo <br/></span>
     <span v-if="comecou" style="font-size: 22px;">A Maior Black de Moda <strong>Country!</strong></span>

     <div v-if="!comecou" class="p-d-flex ktz-timer p-px-6 p-jc-center p-flex-column">
      <span  style=" font-size: 21.5px; margin-top: -12px;" class="ktz-data p-mb-2">De 25 a 29 de novembro</span>
      <span class="" style="font-size: 18px;font-weight: 400;">A Maior Black de Moda <strong>Country!</strong></span>
       <span class="ktz-desconto p-mt-4" style=" font-size: 21.5px;">Faltam: </span>
       <div class="p-d-flex p-jc-center p-mt-2">
          <div class="ktz-dias p-d-flex p-flex-column p-ml-1">
            <div class="ktz-timer-container p-p-2" ref="dias">00</div>
            <div class="ktz-timer-text p-mt-2">Dias</div>
          </div>
          <div class="ktz-horas p-d-flex p-flex-column p-mx-1">
            <div class="ktz-timer-container p-p-2" ref="horas">00</div>
            <div class="ktz-timer-text p-mt-2">Horas</div>
          </div>
          <div class="ktz-minutos p-d-flex p-flex-column p-mx-1">
            <div class="ktz-timer-container p-p-2" ref="minutos">00</div>
            <div class="ktz-timer-text p-mt-2">Minuto</div>
          </div>
          <div class="ktz-segundos p-d-flex p-flex-column p-mr-1">
            <div class="ktz-timer-container p-p-2" ref="segundos">00</div>
            <div class="ktz-timer-text p-mt-2">Segundos</div>
          </div>
       </div>
     </div>
     <div v-if="!comecou" style="font-weight: 500" class="p-d-flex p-mt-4"> 
        Clique no botão e entre no grupo vip!
     </div>
     <!-- <div v-else style="font-weight: 500" class="p-d-flex p-mt-6"> 
       Todo o catálogo com descontos exclusivos.
     </div> -->
      <div v-if="comecou" style="font-weight: 500" class="p-d-flex p-mt-5"> 
        Clique no botão e fale com a vendedora.
     </div>
     <div style="width: 90%" class="p-px-3 p-d-flex p-jc-center">
        <Button @click="selecionarGrupos" style="background-color: #F30708!important; border-color: #F30708;" class="ktz-button p-button-rounded p-mt-4 animate__animated animate__pulse animate__infinite	infinite">
          <span style="background: transparent; width: 100%; color: #FFF" v-if="!comecou">ENTRE NO GRUPO VIP!</span>
          <span style="background: transparent; width: 100%; color: #FFF" v-else>QUERO VER OS DESCONTOS!</span>
        </Button>
     </div>
      <div v-if="!comecou" style="font-weight: 500;" class="p-d-flex p-mt-4"> 
        Descontos exclusivos para quem estiver no grupo.
      </div>
      <div style="font-weight: 500" class="p-d-flex p-mt-2 p-mb-2"> 
        Kits e descontos imperdíveis!
      </div>
      <div v-if="comecou" style="font-weight: 500" class="p-d-flex p-mb-4"> 
       Última promoção do ano!
      </div>
      <div class="rodape p-flex">
          ©️ Traje Country, 2024. Todos Os Direitos Reservados <br> CNPJ 37.740/673.0001-98 <br> CEP: 74946-020 <br> Aparecida de Goiânia - Goiás
      </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref,child, get, set } from "firebase/database";

export default {
  components: {
    Button
  },
  data() {
    return {
      grupos: null,
      comecouBlackFriday: false
    }
  },
  computed: {
    comecou: {
      set(valor) {
        this.comecouBlackFriday = valor;
      },
      get() {
        return this.comecouBlackFriday;
      }
    }
  },
  mounted() {
    this.initFirebase();
    this.initCountdown();
  },
  methods: {
    async initFirebase(){
      initializeApp({
        apiKey: "AIzaSyBajudaMm8hQVvnIX678U-mCjdgQahXiPQ",
        authDomain: "mula-rosa-black-friday.firebaseapp.com",
        databaseURL: "https://mula-rosa-black-friday-default-rtdb.firebaseio.com",
        projectId: "mula-rosa-black-friday",
        storageBucket: "mula-rosa-black-friday.appspot.com",
        messagingSenderId: "914548213343",
        appId: "1:914548213343:web:90d48df5058468d55cbdea",
        measurementId: "G-B6DBJS849C"
      })

      this.grupos = await this.carregarDadosGrupos();
    },
    carregarDadosGrupos(){
      return new Promise((resolve, reject) => {
        const dbRef = ref(getDatabase())
        get(child(dbRef, '/')).then(result => {
          resolve(result.val());
        })
        .catch(e => reject(e));
      });
    },
    initCountdown() {
      const ref = this;
      var countDownDate = new Date("Nov 25, 2024 00:01:00").getTime();
      var intervalo = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if(ref.$refs.dias.innerText !== undefined && ref.$refs.dias !== undefined) {
          ref.$refs.dias.innerText = days < 10 ? '0' + days : days;
          ref.$refs.horas.innerText = hours < 10 ? '0' + hours : hours;
          ref.$refs.minutos.innerText = minutes < 10 ? '0' + minutes : minutes;
          ref.$refs.segundos.innerText = seconds < 10 ? '0' + seconds : seconds;
        }
      
        if (distance < 0) {
          clearInterval(intervalo);
          ref.comecou = true;
        }
      }, 1000);
    },
    selecionarGrupos() {
      if(!this.comecou) {
        /*const grupos = this.grupos.filter(g => g.inscricoes < 280);
        grupos[0].inscricoes += 1;
        this.atualizarDadosDoGrupo();
        window.location.href = grupos[0].url;*/

        window.location.href = "https://devzapp.com.br/api-engennier/camp/redirect/673b6a52978b4e00010c2751";
      }
      else {
        window.location.href = 'https://api.whatsapp.com/send?phone=5562982777959&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Black%20Friday'
      }

      window.fbq('trackCustom', 'Contato_WhatsApp');
    },
    atualizarDadosDoGrupo() {
      const dbRef = ref(getDatabase(), '/');
      set(dbRef, this.grupos)
    }
  }
}
</script>

<style scoped>

div::-webkit-scrollbar {
  display: none;
}
.ktz-homePrincipal {
  text-align: center;
  max-width: 620px;
}
.gallery-cell {
   height: 40px;
   border: 1px solid blue;
}

.gallery-cell > img{
    width: 100%;
    max-width: 320px;
    box-shadow: 0 7px 8px -4px rgb(0 0 0 / 20%), 0 13px 19px 2px rgb(0 0 0 / 14%), 0 5px 24px 4px rgb(0 0 0 / 12%);
}

@media screen and ( min-width: 768px ) {
/* half-width cells for larger devices */
    .gallery-cell { width: 50%; }
}
.ktz-titulo {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 6px;
  font-size: 1.6rem;
  color: #000;
}

.ktz-desconto {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
}

.ktz-data {
  font-weight: 600;
  font-size: 1.5rem;
}
.ktz-timer {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
}

.ktz-timer-text {
  font-size: 0.8rem;
  font-weight: 400;
}

.ktz-timer-container {
  border: 2px solid #F30708;
  width: 70px;
}

.rodape {
    width: 100%;
    height: 40px;
    margin-top: auto;
    font-size: 10px;
    padding: 10px;
    text-align: center;
    letter-spacing: 1px;
    color: #fff;
    background: transparent;
    border-top: 1px solid black;
}

@media (min-width: 320px) and (max-width: 374px)
{
    .rodape {
        font-size: 14px!important;
    }
    .avaliacao {
        font-size: 16px;
    }
}
</style>